import { useEffect, useRef, useState } from 'react';

export type TAriaLabels = Record<string, string | Record<string, string>>;

/**
 * @param ariaLabels object containing all ariaLabels translated based on minimalPlayer uiLanguage
 * @param name aria-label key we want to retrieve
 * @returns a boolean indicating if a the aria-label key requested exists or not
 */
export const isAriaLabelValid = (
  ariaLabels: TAriaLabels,
  name: string,
): ariaLabels is Record<string, string> => {
  return name in ariaLabels && typeof ariaLabels[name] === 'string';
};

/**
 * this hooks allows you to retrieve ariaLabels from minimalPlayer based on language set on the player.
 * @param minimalPlayer minimal player instance
 * @param labels aria-label keys we want to retrieve
 * @returns object containing all ariaLabels translated based on minimalPlayer uiLanguage
 */
export const useAriaLabels = (
  minimalPlayer: any,
  labels: string[],
): Record<string, string> => {
  const labelsRef = useRef(labels);
  const [ariaLabels, setAriaLabels] = useState<TAriaLabels>({});
  const [requestedAriaLabels, setRequestedAriaLabels] = useState<
    Record<string, string>
  >({});

  useEffect(() => {
    const getAriaLabels = async (): Promise<void> => {
      const minimalPlayerAriaLabels = await minimalPlayer?.getAriaLabels();
      setAriaLabels(minimalPlayerAriaLabels);
    };
    if (minimalPlayer) {
      getAriaLabels();
    }
  }, [minimalPlayer]);

  useEffect(() => {
    const tempAriaLabels: Record<string, string> = {};
    labelsRef.current.forEach((label): void => {
      if (isAriaLabelValid(ariaLabels, label)) {
        tempAriaLabels[label] = ariaLabels[label];
      }
    });
    setRequestedAriaLabels(tempAriaLabels);
  }, [ariaLabels]);

  return requestedAriaLabels;
};
