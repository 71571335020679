import { CSSProperties, useContext, useEffect, useRef, useState } from 'react';

import { BusinessTypes, KeyMapping } from '@canalplus/oneplayer-constants';
import { secToFormatHours } from '@canalplus/oneplayer-utils';

import { MinimalPlayerContext } from '../../context';
import { useAriaLabels, useMinimalPlayerIsPlaying } from '../../hooks';
import { Slider } from '../slider/Slider';

import styles from './TvMiniProgressBar.css';

const {
  TV_KEY_MAPPING,
  KEYS: { ENTER, LEFT, RIGHT },
} = KeyMapping;

const {
  SEEK_ARROW_DURATION: { SEEK_MINUS_FIXED_DURATION, SEEK_PLUS_FIXED_DURATION },
  VARIANTS: { MYCANAL },
} = BusinessTypes;

export interface IProps {
  variant?: string;
  onSeek?: () => void | undefined;
  onPlay?: () => void | undefined;
  onPause?: () => void | undefined;
}

/**
 *
 *  Custom hook handling minimal player logic.
 *  this hooks adds event listener to handle key navigation and position update.
 * @param param0 custom hooks props
 * @param param0.onSeek callback to execute when seeking is requested
 * @param param0.onPlay callback to execute when play is requested
 * @param param0.onPause callback to execute when pause is requested
 * @param param0.minimalPlayer minimal player instance
 * @returns the current position and the duration of the content.
 */
const useTvMiniProgressBar = ({
  onSeek,
  onPlay,
  onPause,
  minimalPlayer,
}: IProps & { minimalPlayer: any }): [
  duration: number | null,
  currentPosition: number | null,
] => {
  const [duration, setDuration] = useState<number | null>(
    Math.round(minimalPlayer?.getVideoDuration()) || null,
  );
  const [currentPosition, setCurrentPosition] = useState<number | null>(
    Math.round(minimalPlayer?.getPosition()) || null,
  );

  const isPlaying = useMinimalPlayerIsPlaying(minimalPlayer);
  const isLongPressRef = useRef<boolean>(false);

  useEffect(() => {
    const onKeyDown = (event: KeyboardEvent): void => {
      isLongPressRef.current = true;

      if (
        currentPosition !== null &&
        TV_KEY_MAPPING[LEFT].includes(event.keyCode)
      ) {
        setCurrentPosition(
          Math.max(currentPosition - SEEK_MINUS_FIXED_DURATION, 0),
        );
      } else if (
        currentPosition !== null &&
        duration &&
        TV_KEY_MAPPING[RIGHT].includes(event.keyCode)
      ) {
        setCurrentPosition(
          Math.min(currentPosition + SEEK_PLUS_FIXED_DURATION, duration),
        );
      }
    };

    const onKeyUp = (event: KeyboardEvent): void => {
      isLongPressRef.current = false;

      if (TV_KEY_MAPPING[ENTER].includes(event.keyCode)) {
        if (isPlaying) {
          onPause?.();
          minimalPlayer.pause();
        } else {
          onPlay?.();
          minimalPlayer.play();
        }
      } else if (
        currentPosition !== null &&
        TV_KEY_MAPPING[LEFT].includes(event.keyCode)
      ) {
        onSeek?.();
        minimalPlayer.seekTo(currentPosition);
      } else if (
        currentPosition !== null &&
        TV_KEY_MAPPING[RIGHT].includes(event.keyCode)
      ) {
        onSeek?.();
        minimalPlayer.seekTo(currentPosition);
      }
    };
    document.addEventListener('keydown', onKeyDown);
    document.addEventListener('keyup', onKeyUp);

    return () => {
      document.removeEventListener('keydown', onKeyDown);
      document.removeEventListener('keyup', onKeyUp);
    };
  }, [
    minimalPlayer,
    duration,
    isPlaying,
    currentPosition,
    onSeek,
    onPlay,
    onPause,
  ]);

  useEffect(() => {
    if (minimalPlayer) {
      setDuration(Math.round(minimalPlayer.getVideoDuration()));
    }
  }, [minimalPlayer]);

  useEffect(() => {
    const updatePosition = ({ position }: { position: number }): void => {
      if (!isLongPressRef.current) {
        setCurrentPosition(Math.round(position));
      }
    };
    if (minimalPlayer) {
      minimalPlayer.addEventListener('positionUpdate', updatePosition);
    }
    return () => {
      minimalPlayer?.removeEventListener('positionUpdate', updatePosition);
    };
  }, [isLongPressRef, minimalPlayer]);

  return [duration, currentPosition];
};

/**
 *
 * @param arg0 TvMiniProgressBar component props
 * @param arg0.variant the variant
 * @param arg0.onSeek callback to execute when seeking is requested
 * @param arg0.onPlay callback to execute when play is requested
 * @param arg0.onPause callback to execute when pause is requested
 * @returns progress bar which is synchronised with the player used and allows the user to
 * navigate into the stream.
 */
const TvMiniProgressBar = ({
  variant = MYCANAL,
  onSeek,
  onPlay,
  onPause,
}: IProps): JSX.Element | null => {
  const sliderRef = useRef<HTMLInputElement | null>(null);

  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const [duration, currentPosition] = useTvMiniProgressBar({
    minimalPlayer,
    onSeek,
    onPlay,
    onPause,
  });

  const ariaLabels = useAriaLabels(minimalPlayer, ['ProgressBar']);

  // Samsung TV uses Chrome < 79 which doesn't support CSS Math function
  const currentTimeStyle: CSSProperties = {
    ...(currentPosition !== null &&
      duration && {
        left: `${(currentPosition / duration) * 100}%`,
      }),
    transform: 'translate(-50%, 0)',
  };

  useEffect(() => {
    if (duration !== null && currentPosition !== null && sliderRef.current) {
      const positionInPercentage = (currentPosition / duration) * 100;
      const actionColor = styles[`actionColor--${variant}`];
      const white = styles.colorWhite30;
      sliderRef.current.style.background = `linear-gradient(to right, ${actionColor} 0%, ${actionColor} ${positionInPercentage}%, ${white} ${positionInPercentage}%, ${white} 100%)`;
    }
  }, [duration, currentPosition, sliderRef, variant]);

  if (duration === null || currentPosition === null) {
    return null;
  }

  return (
    <div className={styles.progressBarWrapper}>
      <Slider
        ariaLabel={ariaLabels.ProgressBar}
        ref={sliderRef}
        minValue={0}
        maxValue={duration}
        value={currentPosition}
        isTv
      />
      <div className={styles.timeDisplayBar}>
        {(currentPosition / duration) * 100 <= 90 && (
          <div className={styles.position} style={currentTimeStyle}>
            {secToFormatHours(currentPosition)}
          </div>
        )}
        <div className={styles.duration}>
          {`-${secToFormatHours(duration - currentPosition)}`}
        </div>
      </div>
    </div>
  );
};

export { TvMiniProgressBar };
