import { useCallback, useContext } from 'react';

import { PlayerMinus10Icon } from '@canalplus/dive';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { template } from '@canalplus/oneplayer-utils';

import styles from '../ControlButton.css';

import { Clickable } from '../../../clickable/Clickable';

import { useAriaLabels } from '../../../../hooks';
import { MinimalPlayerContext } from '../../../../context';

const { SEEK_MINUS_FIXED_DURATION } = BusinessTypes.SEEK_ARROW_DURATION;

export interface IProps {
  onClick?: () => void | undefined;
}

const SeekMinusControlButton = ({ onClick }: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);
  const ariaLabels = useAriaLabels(minimalPlayer, ['SeekBackward']);

  const onSeekMinusClick = useCallback((): void => {
    onClick?.();
    minimalPlayer.seekTo({ relative: -SEEK_MINUS_FIXED_DURATION });
  }, [minimalPlayer, onClick]);

  if (!minimalPlayer) {
    return null;
  }

  return (
    <Clickable
      customStyle={styles.control}
      onClick={onSeekMinusClick}
      ariaLabel={template(ariaLabels.SeekBackward, {
        SEEK_MINUS_FIXED_DURATION,
      })}
    >
      <PlayerMinus10Icon />
    </Clickable>
  );
};

export { SeekMinusControlButton };
