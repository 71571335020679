import { useCallback, useRef, useState } from 'react';

/**
 * This hook allows you to track whether an element is being hovered over or not.
 * (strongly inspired from https://usehooks.com/)
 * @returns a custom reference callback to attach to the target element as a react ref (refCb), it adds eventListeners to handle hover status on the node,
 *  the reference node (ref) and the current hovering status (isHovering)
 */
export const useHover = (): [
  ref: React.RefObject<HTMLElement>,
  refCb: React.RefCallback<HTMLElement>,
  isHovered: boolean,
] => {
  const [isHovered, setIsHovered] = useState(false);
  const refNode = useRef<HTMLElement | null>(null);

  const handleMouseEnter = useCallback(() => {
    setIsHovered(true);
  }, []);

  const handleMouseLeave = useCallback(() => {
    setIsHovered(false);
  }, []);

  const customRefCb = useCallback(
    (node: HTMLElement) => {
      if (refNode.current?.nodeType === Node.ELEMENT_NODE) {
        refNode.current.removeEventListener('mouseenter', handleMouseEnter);
        refNode.current.removeEventListener('mouseleave', handleMouseLeave);
      }

      if (node?.nodeType === Node.ELEMENT_NODE) {
        node.addEventListener('mouseenter', handleMouseEnter);
        node.addEventListener('mouseleave', handleMouseLeave);
      }

      refNode.current = node;
    },
    [handleMouseEnter, handleMouseLeave],
  );

  return [refNode, customRefCb, isHovered];
};
