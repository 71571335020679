import { useCallback, useContext } from 'react';

import { PlayerPlus10Icon } from '@canalplus/dive';
import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { template } from '@canalplus/oneplayer-utils';

import { Clickable } from '../../../clickable/Clickable';

import { useAriaLabels } from '../../../../hooks';
import { MinimalPlayerContext } from '../../../../context';

import styles from '../ControlButton.css';

const { SEEK_PLUS_FIXED_DURATION } = BusinessTypes.SEEK_ARROW_DURATION;

export interface IProps {
  onClick?: () => void | undefined;
}

const SeekPlusControlButton = ({ onClick }: IProps): JSX.Element | null => {
  const { minimalPlayer } = useContext(MinimalPlayerContext);

  const ariaLabels = useAriaLabels(minimalPlayer, ['SeekForward']);

  const onSeekPlusClick = useCallback((): void => {
    onClick?.();
    minimalPlayer.seekTo({ relative: SEEK_PLUS_FIXED_DURATION });
  }, [minimalPlayer, onClick]);

  if (!minimalPlayer) {
    return null;
  }

  return (
    <Clickable
      customStyle={styles.control}
      onClick={onSeekPlusClick}
      ariaLabel={template(ariaLabels.SeekForward, {
        SEEK_PLUS_FIXED_DURATION,
      })}
    >
      <PlayerPlus10Icon />
    </Clickable>
  );
};

export { SeekPlusControlButton };
