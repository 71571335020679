import { useEffect, useMemo } from 'react';
import classnames from 'classnames';

import { BusinessTypes } from '@canalplus/oneplayer-constants';
import { getFocusableElements } from '@canalplus/oneplayer-utils';

import { useHover, useIdle, useKeyPressWithinActiveElement } from '../../hooks';
import { MiniBottomControls } from './MiniBottomControls';
import { MiniTopControls } from './MiniTopControls';

import styles from './MiniControls.css';

const { VARIANTS } = BusinessTypes;

const IDLE_TIME = 3000;

interface IDefaultProps {
  isFullFrame?: boolean;
  shouldShowProgressBar?: boolean;
  shouldShowExitButton?: boolean;
  shouldShowControlButtons?: boolean;
  forceFocusOnFullFrame?: boolean;
  isTv?: boolean;
  variant?: string;
}

interface IProps extends IDefaultProps {
  onFullFrame: () => void;
  onExit?: () => void;
  onPlay?: () => void;
  onPause?: () => void;
  onSeekPlus?: () => void;
  onSeekMinus?: () => void;
  onProgressBarSeek?: () => void;
  onMute?: () => void;
  onUnmute?: () => void;
}

const MiniControls = ({
  isTv = false,
  isFullFrame = true,
  shouldShowProgressBar = true,
  shouldShowControlButtons = true,
  shouldShowExitButton = false,
  variant = VARIANTS.MYCANAL,
  forceFocusOnFullFrame,
  onFullFrame,
  onExit,
  onPlay,
  onPause,
  onSeekPlus,
  onSeekMinus,
  onProgressBarSeek,
  onMute,
  onUnmute,
}: IProps): JSX.Element => {
  const idle = useIdle(IDLE_TIME);
  const [ref, refCb, isHovered] = useHover();
  const isKeyPressedWithinActiveElement = useKeyPressWithinActiveElement(
    'Tab',
    ref,
    IDLE_TIME,
  );

  const shouldHideMiniControls =
    (idle && (isHovered || isTv)) ||
    (!isHovered && !isTv) ||
    !isKeyPressedWithinActiveElement;

  const shouldShowMiniControls =
    (!idle && (isHovered || isTv)) || isKeyPressedWithinActiveElement;

  useEffect(() => {
    if (ref.current && forceFocusOnFullFrame && isFullFrame) {
      getFocusableElements(ref.current)?.[0]?.focus();
    }
  }, [forceFocusOnFullFrame, isFullFrame, ref]);

  return useMemo(
    () => (
      <div
        ref={refCb}
        className={classnames(styles.miniControls, {
          [styles.hidden]: shouldHideMiniControls,
          [styles.show]: shouldShowMiniControls,
        })}
      >
        {!isTv && (
          <MiniTopControls
            shouldShowExitButton={shouldShowExitButton}
            onExit={onExit}
            onMute={onMute}
            onUnmute={onUnmute}
          />
        )}
        <MiniBottomControls
          isTv={isTv}
          variant={variant}
          shouldShowProgressBar={shouldShowProgressBar}
          shouldShowControlButtons={shouldShowControlButtons}
          isFullFrame={isFullFrame}
          onFullFrame={onFullFrame}
          onPlay={onPlay}
          onPause={onPause}
          onSeekPlus={onSeekPlus}
          onSeekMinus={onSeekMinus}
          onProgressBarSeek={onProgressBarSeek}
        />
      </div>
    ),
    [
      refCb,
      shouldHideMiniControls,
      shouldShowMiniControls,
      isTv,
      shouldShowExitButton,
      onExit,
      onMute,
      onUnmute,
      variant,
      shouldShowProgressBar,
      shouldShowControlButtons,
      isFullFrame,
      onFullFrame,
      onPlay,
      onPause,
      onSeekPlus,
      onSeekMinus,
      onProgressBarSeek,
    ],
  );
};

export { MiniControls };
