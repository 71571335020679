// Keys labels have to match those used and sent to us by the mycanal webapp
// Do not change them
export enum KEYS {
  LEFT = 'left',
  RIGHT = 'right',
  UP = 'up',
  DOWN = 'down',
  ENTER = 'enter',
  BACK = 'back',
  SPACE = 'space',
  BACKSPACE = 'backspace',
  TAB = 'tab',
  FULLSCREEN = 'fullscreen',
  MUTE = 'mute',
  CYCLEPLAYBACKRATE = 'cycleplaybackrate',
  REWIND = 'rewind',
  FORWARD = 'forward',
  STOP = 'stop',
  PLAY = 'play',
  PAUSE = 'pause',
  PLAYPAUSE = 'playpause',
  LANGUAGES = 'languages',
  SUBTITLES = 'subtitles',
  PREVPROG = 'prevprog',
  NEXTPROG = 'nextprog',
}

export enum SEQUENCES {
  KONAMI = "&&((%'%'",
  KONAMI_XBOX = 'ËËÌÌÍÎÍÎ',
  CIRCULAR = "&'(%&%('&",
  CIRCULAR_XBOX = 'ËÎÌÍËÍÌÎË',
  CATCHME = 'catchme',
}

const defaultArrowKeysMapping = {
  [KEYS.LEFT]: [37, 205], // left arrow key (37) and left key on xbox controller (205)
  [KEYS.UP]: [38, 203], // up arrow key (38) and up key on xbox controller (203)
  [KEYS.RIGHT]: [39, 206], // right arrow key (39) and right key on xbox controller (206)
  [KEYS.DOWN]: [40, 204], // down arrow key (40) and down key on xbox controller (204)
};

export const WEB_KEY_MAPPING = {
  ...defaultArrowKeysMapping,

  [KEYS.BACK]: 27, // escape (27) key
  [KEYS.BACKSPACE]: 8, // Backspace (8) key
  [KEYS.SPACE]: 32, // space (32) key
  [KEYS.TAB]: 9, // tab key (9), mainly used by the utils getActivityObservable in relation with ActivityDetector

  [KEYS.FULLSCREEN]: 70, // F (70) key
  [KEYS.MUTE]: 77, // M (77) key
  [KEYS.CYCLEPLAYBACKRATE]: 88, // X (88) key
};

export const TV_KEY_MAPPING = {
  ...defaultArrowKeysMapping,

  [KEYS.ENTER]: [13, 195], // Enter (13) key and A key on xbox controller (195)
  [KEYS.BACK]: [27, 81, 196], // Escape (27) and Q (81) keys and B key on xbox controller (196)
  [KEYS.BACKSPACE]: 8, // Backspace (8) key
  [KEYS.SPACE]: 32, // Space (32) key

  [KEYS.REWIND]: [118, 151, 87, 199], // F7 (118 - used by playstation dualshock controller), AirplaneMode (151 - used by playstation remote control) and W (87 - used for dev tests) keys and Y key on xbox controller (199)
  [KEYS.FORWARD]: [119, 143, 65, 200], // F8 (119 - used by playstation dualshock controller), F32 (143 - used by playstation remote control) and A (65 - used for dev tests) keys and X key on xbox controller (200)

  // Will trigger stop (178 - used by playstation remote control) and C (67 - used for dev tests)
  // and stop (413 - used by samsung/lg/hisense) keys
  [KEYS.STOP]: [178, 67, 413],
  // Will trigger play/pause (179 - used by playstation remote control) and V (86 - used for dev tests)
  // and play (415 - used by samsung/lg/hisense) keys
  [KEYS.PLAY]: [179, 86, 415],
  // Will trigger 217 (used by playstation remote control) and B (66 - used for dev tests)
  // and playpause (10252 - used by samsung/lg and 463 - used by hisense) keys
  [KEYS.PLAYPAUSE]: [217, 66, 10252, 463],
  // Will trigger F25 (135 - used by playstation remote control) and N (78 - used for dev tests)
  // and pause (19 - used by samsung/lg/hisense) keys
  [KEYS.PAUSE]: [135, 78, 19],
  // TODO: The following actions are not fully implemented yet (no user hint on how to use it)
  [KEYS.LANGUAGES]: 79, // O (79), Will open LanguagesModal
  [KEYS.SUBTITLES]: 89, // Y (89), Will open SubtitlesParametersModal
  // To zap to prev channel, will trigger S (83)
  // and prevprog (33 - used by lg/hisense and 427 - used by samsung) keys
  [KEYS.PREVPROG]: [83, 33, 427],
  // To zap to next channel, will trigger Z (90)
  // and nextprog (34 - used by lg/hisense and 428 - used by samsung) keys
  [KEYS.NEXTPROG]: [90, 34, 428],
};

export const TWO_PLAYER_KEY_MAPPING = {
  ...defaultArrowKeysMapping,

  [KEYS.BACK]: [27, 81], // Escape (27) and Q (81) keys
  [KEYS.SPACE]: 32, // Space (32) key

  [KEYS.FULLSCREEN]: 70, // F (70) key
  [KEYS.MUTE]: 77, // M (77) key
};
