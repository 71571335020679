import { TvMiniProgressBar } from './TvMiniProgressBar';
import { WebMiniProgressBar } from './WebMiniProgressBar';

interface IProps {
  isTv?: boolean;
  variant?: string;
  onSeek?: () => void | undefined;
  onPlay?: () => void | undefined;
  onPause?: () => void | undefined;
}

/**
 *
 * @param param0 MiniProgressBar component props
 * @param param0.isTv web or tv interface
 * @param param0.variant the variant
 * @param param0.onSeek callback to execute when seeking is requested
 * @param param0.onPlay callback to execute when play is requested
 * @param param0.onPause callback to execute when pause is requested
 * @returns MiniProgressBar component for web or tv interface
 */
const MiniProgressBar = ({
  isTv,
  variant,
  onSeek,
  onPlay,
  onPause,
}: IProps): JSX.Element => {
  switch (isTv) {
    case true:
      return (
        <TvMiniProgressBar
          onSeek={onSeek}
          onPlay={onPlay}
          onPause={onPause}
          variant={variant}
        />
      );
    case false:
    default:
      return <WebMiniProgressBar onSeek={onSeek} variant={variant} />;
  }
};

export { MiniProgressBar };
