import { useEffect, useState } from 'react';

/**
 * This hooks allow you to identify if the muted status of the player has changed.
 * @param minimalPlayer minimal player instance
 * @returns a boolean indicating if the player is muted or not
 */
export const useMinimalPlayerIsMuted = (minimalPlayer: any): boolean => {
  const [isMuted, setIsMuted] = useState<boolean>(false);

  useEffect(() => {
    const onVolumeChange = ({ muted }: { muted: boolean }): void => {
      if (muted) {
        setIsMuted(true);
      } else {
        setIsMuted(false);
      }
    };
    if (minimalPlayer) {
      setIsMuted(minimalPlayer.player.isMute());
      minimalPlayer.addEventListener('volumeChange', onVolumeChange);
    }

    return () => {
      minimalPlayer?.removeEventListener('volumeChange', onVolumeChange);
    };
  }, [minimalPlayer]);

  return isMuted;
};
